import type { Models } from '@rematch/core';
import { loading } from './loading.model';
import { users } from './users.model';
import { auth } from './auth.model';
import { account } from './account.model';
import { healthCategories } from './healthCategories.model';
import { user } from './user.model';

export interface RootModel extends Models<RootModel> {
  users: typeof users;
  auth: typeof auth;
  loading: typeof loading;
  healthCategories: typeof healthCategories;
  account: typeof account;
  user: typeof user;
}
export const models: RootModel = {
  users,
  auth,
  loading,
  healthCategories,
  account,
  user,
};
